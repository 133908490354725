@tailwind base;
@tailwind components;
@tailwind utilities;

body,
html,
#__next {
  @apply h-full;
}

body {
  @apply bg-silver-5;
}
